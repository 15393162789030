.apply-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  min-width: 500px;
  gap: 15px;
  min-width: 550px;;
}

.apply-page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.apply-page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 3px;
  color: #830F5B;
  border: 2px solid #830F5B;
  border-radius: 10px;
}

.apply-active{
  background-color: #830F5B;
  color: #FFFFFF;
  border-radius: 3px;
  border-radius: 10px;
}

.apply-link-active {
  color: #FFFFFF;
}

.apply-nav-button {
  display: flex;
}
.apply-nav-previous {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background-color: #830F5B;
  height: 30px;
  width: 50px;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.apply-nav-next {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background-color: #830F5B;
  height: 30px;
  width: 50px;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.apply-nav-break {
  display: flex;
}