.walletCard {
    width: 271px;
    box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.04);
    border-radius: 18px;
    padding: 5px;
}

.walletCard-Image {
    height: 267px;
    position: relative;
}

.cardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px 18px 0px 0px;;
}

.cardEdit {
    position: absolute;
    bottom: 15px;
    right: 10px;
    background-color: #ffffff54;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.15s linear;
    width: 20px;
    height: 20px;
    display: flex;
}

.cardEdit:hover {
    width: 24px;
    height: 24px;
}

.cardEdit-image {
    flex-grow: 1;
    display: block;
    object-fit: cover;
    width: 20px;
}

.walletCard-Info{
    text-align: left;
    padding: 0 10px 10px 0px;
}

.walletCard-Info p{
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    margin: 8px
}