.applications-main {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "trade-gothic";
  box-sizing: border-box;
}

.applications-titleSection{
  padding: 10px 40px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.applications-title{
  padding: 22px 0px 22px 15px;
  border-bottom: 1px solid  #EDEDED;
  text-align: left;
  margin: 0;
  font-family: 'trade-gothic';
  font-size: 36px;
  color: #84004C;
}

.applications-subscribers-table,
.applications-subscribers-table table{
  width: 100%;
  /* width: 1100px;
  display: block;
  overflow-x: scroll; */
}

.applications-subscribers-table th{
  background: #F5F5F5;
  font-size: 21px;
}
.applications-subscribers-table td{
  font-size: 17px;
  color: #676767;
}

.applications-subscribers-table th,
.applications-subscribers-table td{
  padding: 20px 5px;
}

td.application-leftPad,
th.application-leftPad{
  padding-left: 30px;
  text-align: left;
}

.application-ApplicationRole{
  text-transform: capitalize;
}

.application-edit img{
  width: 17px;
  height: 17px;
  filter: brightness(1.9);
  cursor: pointer;
}

td.application-edit{
  padding: 0 50px;
}

td.application-applicationName{
  color: #676767;
}

td.application-applicationId {
  color: #830F5B;
}

.application-Status{
  display: flex;
  justify-content: center;
}

th.applicationTitle-small{
  width: 12%;
}

.row-list:hover {
  background-color: #edc6df;
  transition: .2s;
}

.row-list {
  cursor: pointer;
}

.pagination {
  width: 1000px;
}

.btn-pag {
  display: block;
  float: right;
  max-width: 240px;
  background: #FFFFFF;
  border: 1px solid #830F5B;
  border-radius: 10px;
  padding: 15px;
  color: #84004C;
  cursor: pointer;
  margin-left: auto;
  text-decoration: none;
}

.btn-pag-disabled {
  display: block;
  float: right;
  max-width: 240px;
  background: #FFFFFF;
  border: 1px solid #989196;
  border-radius: 10px;
  padding: 15px;
  color: #989196;
  cursor: pointer;
  margin-left: auto;
  text-decoration: none;
}