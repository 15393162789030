.mcontainerDayDiv{
    /*margin: 0px;*/
    margin: 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.mcontainer__dayClassStart{
    background: linear-gradient(to right, #FFFFFF 50%, #EDEDED 100%);
    box-sizing: border-box;
}
.mcontainer__dayClassEnd{
    background: linear-gradient(to right, #EDEDED 50%, #FFFFFF 100%);
    box-sizing: border-box;
}

.mcontainer__betweenDays{
    background: #EDEDED;
    box-sizing: border-box;
}

.mseveral{
    background: #F4F3E6;
    border-radius: 5px;
    box-sizing: border-box;
}

.malmost{
    background: rgba(250, 183, 131, 0.65);
    border-radius: 5px;
    box-sizing: border-box;
}

.mdayDiv{
    height: 25px;
    width: 25px;
    display: grid;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.mdayClass{
    cursor:pointer;
    box-sizing: border-box;
}

.mdayClass:hover{
    border: 1px solid #84004C;
    border-radius: 5px;
}

.mdayClassUnique,
.mdayClassEnd,
.mdayClassStart{
    color: #FFFFFF;
    background-color: #84004C;
    border-radius: 5px;
    box-sizing: border-box;
}

.mdisabledDay{
    color: #D4D4D4;
}

.mdayClassStart:hover,
.mdisabledDay:hover{
    cursor: default;
}

.mbetweenDays{
    cursor: pointer;
    box-sizing: border-box;
}

.mclickable:hover{
    cursor: pointer;
}