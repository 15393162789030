.infoTooltipApp {
    position: relative;
    display: inline;
    object-fit: cover;
    height: 100%;
    margin-left: 5px;

}

.infoTooltipApp:hover .infoToolTipMessage{
    visibility: visible;
    opacity: 0.98;
    left: 15px;
}

.helpIcon{
    height: 15px;
    object-fit: cover;
}

.infoToolTipMessage{
    position: absolute;
    width: 300px;
    background: #c5c4c0;
    color: #888787;
    border:none;
    border-radius: 20px;
    left: 10px;
    padding: 20px;
    text-align: justify;
    font-family: 'montserrat-regular';
    font-size: 13px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear
}