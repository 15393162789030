.users-main {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "trade-gothic";
    box-sizing: border-box;
}

.users-titleSection{
    padding: 10px 40px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.users-title{
    padding: 22px 0px 22px 15px;
    border-bottom: 1px solid  #EDEDED;
    text-align: left;
    margin: 0;
    font-family: 'trade-gothic';
    font-size: 36px;
    color: #84004C;
}

.users-subscribers-table,
.users-subscribers-table table{
    width: 100%;
}

.users-subscribers-table th{
    background: #F5F5F5;
    font-size: 21px;
}
.users-subscribers-table td{
    font-size: 17px;
    color: #676767;
}

.users-subscribers-table th,
.users-subscribers-table td{
    padding: 20px 5px;
}

td.user-leftPad,
th.user-leftPad{
    padding-left: 30px;
    text-align: left;
}

.user-UserRole{
    text-transform: capitalize;
}

.user-edit img{
    width: 17px;
    height: 17px;
    filter: brightness(1.9);
    cursor: pointer;
}

td.user-edit{
    padding: 0 50px;
}

td.user-userName{
    color:#830F5B;
}

.user-Status{
    display: flex;
    justify-content: center;
}

th.userTitle-small{
    width: 12%;
}