.reservationsApp{
    max-width: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.reservations-titleSection{
    padding: 0px 40px;
    height: 100%;
}

.reservations-title{
    padding: 22px 0px 22px 5px;
    border-bottom: 1px solid  #EDEDED;
    text-align: left;
    margin: 0;
    font-family: 'trade-gothic';
    font-size: 36px;
    color: #84004C;
}