.reservations-table{
    margin: auto;
    width: 100%;
    border-collapse: collapse;
}

.reservations-table th{
    background: #F5F5F5;
    font-size: 16px;
    font-family: 'trade-gothic';
    padding: 15px 0px;
}

.reservations-table td{
    color: #676767;
    font-size: 14px;
    font-family: 'trade-gothic';
    padding: 15px 0px;
}

.reservations-t-date{
    width: 12%;
}

.reservations-t-dynamic{
    width: 10%;
}


th.reservations-t-email,
td.reservations-t-email{
    text-align: left;
    padding-left: 15px;
}

