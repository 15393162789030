.eventApp{
    box-sizing: border-box;
    padding: 50px;
}

.events-main__NewEvent{
    padding: 0px;
}

.newEvent-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 240px;
    background: #FFFFFF;
    border: 1px solid #830F5B;
    border-radius: 10px;
    padding: 15px;
    color: #84004C;
    cursor: pointer;
    margin-left: auto;
    text-decoration: none;
}

.newEvent-button:hover {
    background: #fff5f5;
}

.newEvent-buttonTitle {
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;

}

.newEvent-btnImg {
    width: 30px;
    height: 30px;
    background-size: contain;
}

.events-main__ListEvent{
    width: 100%;
}