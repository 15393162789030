.containerDayDiv{
    /*margin: 0px;*/
    margin: 2px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.container__dayClassStart{
    background: linear-gradient(to right, #FFFFFF 50%, #EDEDED 100%);
}
.container__dayClassEnd{
    background: linear-gradient(to right, #EDEDED 50%, #FFFFFF 100%);
}

.container__betweenDays{
    background: #EDEDED;
}

.several{
    background: #F4F3E6;
    border-radius: 5px;
}

.almost{
    background: rgba(250, 183, 131, 0.65);
    border-radius: 5px;
}

.dayDiv{
    height: 22px;
    width: 24px;
    display: grid;
    justify-content: center;
    align-items: center;
}

.dayClass{
    cursor:pointer;
    box-sizing: border-box;
}

.dayClass:hover{
    border: 1px solid #84004C;
    border-radius: 5px;
}

.dayClassUnique,
.dayClassEnd,
.dayClassStart{
    color: #FFFFFF;
    background-color: #84004C;
    border-radius: 5px;
}

.disabledDay{
    color: #a8a8a8;
    box-sizing: border-box;
}

.dayClassStart:hover,
.disabledDay:hover{
    /*cursor: default;*/
    cursor: pointer;
    border: 1px solid #84004C;
    border-radius: 5px;
}

.betweenDays{
    cursor: pointer;
}

.clickable:hover{
    cursor: pointer;
}